
const mode = "DEV";
// const mode = "PROD";
const area = "DEV";
// const area = "PROD";

export const WEB_URL =
    mode === "PROD"
        ? "https://support-api.vytechenterprise.com"
        : "https://api.tickets.vytech.co";

export const BASE_URL_UPLOAD =
    mode === "PROD"
        ? "https://support-api.vytechenterprise.com/upload"
        : "https://api.tickets.vytech.co/upload";

export const AREA_URL =
    area === "PROD"
        ? "https://support-api.vytechenterprise.com/area-of-concern"
        : "https://api.tickets.vytech.co/area-of-concern";

// export const KB_URL =
//     mode === "PROD"
//         ? "https://support-api.vytechenterprise.com/knowledge-base"
//         // ? "https://support-api.vytechenterprise.com/tickets/allresolution?limit=100&offset=0"
//         : "https://api.tickets.vytech.co/knowledge-base";
// // : "https://api.tickets.vytech.co/tickets/allresolution?limit=100&offset=0";
